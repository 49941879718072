var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "", "align-baseline": "", "ma-0": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs6: "", "py-0": "", "pl-0": "", "self-center": "" } },
            [
              _c(
                "v-menu",
                {
                  ref: "dialog",
                  attrs: {
                    "close-on-content-click": false,
                    "nudge-right": "100",
                    "full-width": "",
                    "max-width": "290px",
                    "min-width": "290px"
                  },
                  model: {
                    value: _vm.fromDateMenu,
                    callback: function($$v) {
                      _vm.fromDateMenu = $$v
                    },
                    expression: "fromDateMenu"
                  }
                },
                [
                  _c("v-text-field", {
                    staticClass: "input input-date",
                    attrs: {
                      slot: "activator",
                      placeholder: "DD-MM-YYYY",
                      label: "Datum vanaf",
                      clearable: _vm.clearable,
                      "error-messages": _vm.fromDate.errorMessage,
                      "prepend-icon": "filter_list",
                      "hide-details": ""
                    },
                    on: {
                      "click:clear": function($event) {
                        _vm.fromDate.clear()
                        _vm.handleChange()
                      },
                      "click:prepend": function($event) {
                        return _vm.showDateFilterDialog("fromDate")
                      }
                    },
                    slot: "activator",
                    model: {
                      value: _vm.fromDate.formattedDate,
                      callback: function($$v) {
                        _vm.$set(_vm.fromDate, "formattedDate", $$v)
                      },
                      expression: "fromDate.formattedDate"
                    }
                  }),
                  _c("v-date-picker", {
                    ref: "picker",
                    attrs: {
                      "first-day-of-week": "1",
                      locale: "nl-nl",
                      min: "1910-01-01",
                      color: "#837f16"
                    },
                    on: {
                      change: function($event) {
                        _vm.handleChange()
                        _vm.fromDateMenu = false
                      }
                    },
                    model: {
                      value: _vm.fromDate.selectedDate,
                      callback: function($$v) {
                        _vm.$set(_vm.fromDate, "selectedDate", $$v)
                      },
                      expression: "fromDate.selectedDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs6: "", "py-0": "", "pr-0": "" } },
            [
              _c(
                "v-menu",
                {
                  ref: "dialog",
                  attrs: {
                    "close-on-content-click": false,
                    "nudge-right": "100",
                    "full-width": "",
                    "max-width": "290px",
                    "min-width": "290px"
                  },
                  model: {
                    value: _vm.toDateMenu,
                    callback: function($$v) {
                      _vm.toDateMenu = $$v
                    },
                    expression: "toDateMenu"
                  }
                },
                [
                  _c("v-text-field", {
                    staticClass: "input input-date",
                    attrs: {
                      slot: "activator",
                      placeholder: "DD-MM-YYYY",
                      label: "Datum tot",
                      clearable: _vm.clearable,
                      "error-messages": _vm.toDate.errorMessage,
                      "hide-details": ""
                    },
                    on: {
                      "click:clear": function($event) {
                        _vm.toDate.clear()
                        _vm.handleChange()
                      }
                    },
                    slot: "activator",
                    model: {
                      value: _vm.toDate.formattedDate,
                      callback: function($$v) {
                        _vm.$set(_vm.toDate, "formattedDate", $$v)
                      },
                      expression: "toDate.formattedDate"
                    }
                  }),
                  _c("v-date-picker", {
                    ref: "picker",
                    attrs: {
                      "first-day-of-week": "1",
                      locale: "nl-nl",
                      min: "1910-01-01",
                      color: "#837f16"
                    },
                    on: {
                      change: function($event) {
                        _vm.handleChange()
                        _vm.toDateMenu = false
                      }
                    },
                    model: {
                      value: _vm.toDate.selectedDate,
                      callback: function($$v) {
                        _vm.$set(_vm.toDate, "selectedDate", $$v)
                      },
                      expression: "toDate.selectedDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isShowingDateFilterDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "30vw" },
              model: {
                value: _vm.isShowingDateFilterDialog,
                callback: function($$v) {
                  _vm.isShowingDateFilterDialog = $$v
                },
                expression: "isShowingDateFilterDialog"
              }
            },
            [
              _c("DateFilterDialog", {
                attrs: { type: _vm.dateFilterType },
                on: { applyDateFilters: _vm.applyDateFilters },
                model: {
                  value: _vm.isShowingDateFilterDialog,
                  callback: function($$v) {
                    _vm.isShowingDateFilterDialog = $$v
                  },
                  expression: "isShowingDateFilterDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }