import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { DateFormatter } from '@/support/DateFormatter';

@Component<PeriodFilter>({})
export default class PeriodFilter extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */

  @Prop({ default: true })
  protected clearable!: boolean;

  @Prop()
  protected to!: string;

  @Prop()
  protected from!: string;

  // #endregion
  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion
  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

  protected toDate = new DateFormatter();

  protected fromDate = new DateFormatter();

  protected dateFilterType = '';

  protected isShowingDateFilterDialog = false;

  protected toDateMenu = false;

  protected fromDateMenu = false;
  // #endregion
  // #region Lifecycle Hooks / Init

  protected mounted() {
    this.applyDateFilters(this.from, this.to);
  }
  // #endregion
  // #region Class methods

  protected handleChange() {
    this.$emit('change', this.fromDate.selectedDate, this.toDate.selectedDate);
  }

  protected applyDateFilters(from: string, to: string) {
    this.fromDate.selectedDate = from;
    this.toDate.selectedDate = to;
    this.$emit('change', from, to);
  }

  protected showDateFilterDialog(type: string) {
    this.dateFilterType = type;
    this.isShowingDateFilterDialog = true;
  }

  // #endregion
  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */
  // #endregion
  // #region Getters & Setters

  // #endregion
  // #region @Watchers

  @Watch('to')
  @Watch('from')
  protected dateChanged() {
    this.applyDateFilters(this.from, this.to);
  }
  // #endregion
}
